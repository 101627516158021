<template>
  <div class="table">
    <div class="table__header">
      <div
        class="table__cell table__header-item table__header-item_status"
        v-for="item in header_items"
        :key="item.name"
        @click="$emit('change_sorted', item.name)"
      >
        <span class="table__header-title">{{ item.title }}</span>
        <div class="table__header-arrows">
          <div
            class="table__header-arrow table__header-top-arrow"
            :class="{
              'table__header-arrow_pressed': item.sorted === 1,
            }"
          ></div>
          <div
            class="table__header-arrow table__header-bottom-arrow"
            :class="{
              'table__header-arrow_pressed': item.sorted === -1,
            }"
          ></div>
        </div>
      </div>
      <div class="table__cell table__header-item"></div>
    </div>
    <div class="table__data table__data-container">
      <template v-for="item in items">
        <div class="table__row" :key="item.id">
          <div class="table__cell table__cell_status">
            <div class="table__cell-text">{{ item.date }}</div>
          </div>
          <div class="table__cell">
            <div class="table__cell-text">
              {{ log_description(item.description) }}
            </div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  name: "statusLogsTable",
  props: {
    header_items: Array,
    items: Array,
    rows_filter: Map,
  },
  computed: {},
  methods: {
    show_cell(item) {
      return this.header_items.find((header) => header.name === item);
    },
    log_description(event_name) {
      switch (event_name) {
        case "copy_error":
          return this.$i18n.t("log.copy_error");
        case "decrypt_error":
          return this.$i18n.t("log.decrypt_error");
        case "upload_error":
          return this.$i18n.t("log.upload_error");
        case "usb_error":
          return this.$i18n.t("log.usb_error");
        case "block_error":
          return this.$i18n.t("log.block_error");
        case "hub_added":
          return this.$i18n.t("log.hub_added");
        case "hub_deleted":
          return this.$i18n.t("log.hub_deleted");
        case "hub_offline":
          return this.$i18n.t("log.hub_offline");
        case "hub_online":
          return this.$i18n.t("log.hub_online");
        case "hub_settings_cloud":
          return this.$i18n.t("log.hub_settings_cloud");
        case "hub_settings_hub":
          return this.$i18n.t("log.hub_settings_hub");
        case "meta_upload_error":
          return this.$i18n.t("log.meta_upload_error");
        default:
          return this.$i18n.t("log.unknown_error");
      }
    },
  },
};
</script>

<style scoped>
.table__data-container {
  max-height: 480px;
  overflow-y: auto;
}
</style>
