<template>
  <div class="table">
    <div class="table__header">
      <div
        class="table__cell table__header-item"
        v-for="item in header_items"
        :key="item.name"
        :class="item.name"
        :style="item.name === active_name ? active_style : ''"
        @click="
          $emit('change_sorted', item.name);
          set_active_style(item.name);
        "
      >
        <span class="table__header-title">{{ item.title }}</span>
        <div class="table__header-arrows">
          <div
            class="table__header-arrow table__header-top-arrow"
            :class="{
              'table__header-arrow_pressed': item.sorted === 1,
            }"
          ></div>
          <div
            class="table__header-arrow table__header-bottom-arrow"
            :class="{
              'table__header-arrow_pressed': item.sorted === -1,
            }"
          ></div>
        </div>
      </div>
    </div>
    <div class="table__data">
      <template v-for="item in items">
        <div class="table__row" :key="item.hub_id">
          <div
            class="table__cell"
            v-for="cell in Object.keys(item)"
            :key="cell"
            v-show="show_cell(cell)"
            @click="set_active_style(cell)"
            :class="cell"
            :style="cell === active_name ? active_style : ''"
          >
            <div class="table__cell-text">{{ item[cell] }}</div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  name: "statusTable",
  props: {
    header_items: Array,
    items: Array,
    rows_filter: Map,
  },
  data() {
    return {
      active_name: "",
      active_style: "",
    };
  },
  watch: {
    items: {
      handler: function () {
        this.active_name = "";
      },
      deep: true,
    },
  },
  computed: {},
  methods: {
    show_cell(item) {
      return this.header_items.find((header) => header.name === item);
    },
    set_active_style(name) {
      let active_width = 0;
      let fn = false;

      let collection = document.getElementsByClassName(name);
      for (let item of collection) {
        if (
          item.scrollWidth > item.clientWidth &&
          item.scrollWidth > active_width
        ) {
          active_width = item.scrollWidth;
          fn = true;

          item.addEventListener("transitionend", this.set_overflow);
        }
      }

      if (fn) {
        this.active_name = name;
        this.active_style = `flex: 0 0 ${active_width}px;`;
      }
    },

    set_overflow() {
      this.active_style += "overflow: visible";
    },
  },
};
</script>

<style scoped></style>
